import { Icon } from "@chakra-ui/react";

function Devin(props) {
  return (
    <Icon w="303" h="96" viewBox="0 0 303 96" {...props}>
      <mask id="path-1-inside-1_11_7" fill="white">
        <path
          className="name-path"
          d="M11.375 86.4375C10.2917 87.1458 9.45833 87.125 8.875 86.375C8.29167 85.625 8.47917 84.8333 9.4375 84C16.6042 77.375 23.0208 73.1458 28.6875 71.3125C34.3542 69.4792 41.8125 71.5 51.0625 77.375C59.2708 82.625 66.5417 84.8958 72.875 84.1875C79.2083 83.4375 83.8333 79.6667 86.75 72.875C91.75 61.0833 90.2708 49.3958 82.3125 37.8125C74.3958 26.2292 63.8333 18.875 50.625 15.75C41.4583 13.5833 32.2917 14.375 23.125 18.125C13.9583 21.875 8.5625 27.4583 6.9375 34.875C4.9375 43.7917 6.83333 50.2708 12.625 54.3125C18.4583 58.3542 24.4583 59.6042 30.625 58.0625C31.4167 57.8542 32.2083 57.5625 33 57.1875C34.125 56.6875 34.9167 56.8542 35.375 57.6875C35.875 58.5208 35.5417 59.25 34.375 59.875C32.7917 60.5417 31.7708 60.9375 31.3125 61.0625C25.3958 62.9375 18.8542 61.9167 11.6875 58C4.52083 54.0833 0.9375 46.4375 0.9375 35.0625C0.9375 28.7708 2.91667 23.0625 6.875 17.9375C10.8333 12.7708 15.8958 8.64584 22.0625 5.5625C28.2292 2.4375 35 0.645837 42.375 0.187503C49.7917 -0.312497 57.0625 0.54167 64.1875 2.75C71.8958 5.66667 78.3542 9.875 83.5625 15.375C88.8125 20.875 92.625 26.9792 95 33.6875C97.375 40.3958 98.2083 47.3333 97.5 54.5C96.8333 61.6667 94.4583 68.3333 90.375 74.5C84.125 83.875 76.5208 90.1042 67.5625 93.1875C58.6042 96.3125 47.2083 94.1667 33.375 86.75C29.875 84.875 26.2917 83.5625 22.625 82.8125C19 82.0625 15.25 83.2708 11.375 86.4375ZM47.25 71.8125C46.0833 72.3958 45.2708 72.3125 44.8125 71.5625C44.3542 70.7708 44.5833 70.0417 45.5 69.375C50.2917 66.125 51.1458 62.75 48.0625 59.25C45.0208 55.75 42.2083 52.875 39.625 50.625C31.0833 43.3333 28.2917 37 31.25 31.625C34.2083 26.25 38.9583 21.75 45.5 18.125C46.6667 17.5417 47.4792 17.6667 47.9375 18.5C48.4375 19.2917 48.1667 20.0208 47.125 20.6875C40.9167 24.5625 42.1667 30.1458 50.875 37.4375C58.75 44.0208 62.2292 50.2292 61.3125 56.0625C60.3958 61.8958 55.7083 67.1458 47.25 71.8125Z"
        />
        <path
          className="name-path"
          d="M123 94.75C122.917 93.75 122.375 93 121.375 92.5C120.417 92 119.604 91.5 118.938 91C114.146 87.75 110.75 83.7917 108.75 79.125C106.792 74.4167 105.688 69.4375 105.438 64.1875C104.938 52.3125 108.146 43.2708 115.062 37.0625C117.521 34.8542 120.354 33.0625 123.562 31.6875C126.771 30.2708 130.062 29.125 133.438 28.25C134.479 28.0417 135.25 28.5625 135.75 29.8125L144.75 50.9375C145.167 52.0625 145.021 52.7708 144.312 53.0625H144.188L118.562 63C118.062 63.25 117.854 63.6458 117.938 64.1875C119.979 73.4375 123.396 79.375 128.188 82C132.979 84.5833 137.75 84.9583 142.5 83.125C143.333 82.75 143.854 82.8125 144.062 83.3125C144.312 83.8125 144.167 84.1875 143.625 84.4375C140.25 86.1458 137.021 87.8958 133.938 89.6875C130.896 91.4792 128.167 93.3542 125.75 95.3125C125.375 95.5625 124.812 95.6458 124.062 95.5625C123.354 95.4792 123 95.2083 123 94.75ZM117.188 59.4375C117.229 59.7708 117.375 60.0417 117.625 60.25C117.875 60.4167 118.188 60.4167 118.562 60.25L131.875 55.25C132.5 55 132.688 54.5625 132.438 53.9375L124.812 35.25C124.438 34.3333 123.688 34.2083 122.562 34.875C120.146 36.2917 118.5 39.5208 117.625 44.5625C116.75 49.6042 116.604 54.5625 117.188 59.4375Z"
        />
        <path
          className="name-path"
          d="M155.875 86.5C155.083 86.7917 154.646 86.6667 154.562 86.125C154.479 85.5417 154.792 84.9167 155.5 84.25C160.292 77.875 162.688 71.875 162.688 66.25C162.729 60.625 160.75 55.7708 156.75 51.6875C150.042 44.8125 147.167 39.4167 148.125 35.5C149.083 31.5417 152.312 26.8125 157.812 21.3125C158.354 20.7292 158.833 20.625 159.25 21C159.708 21.375 159.688 21.8542 159.188 22.4375C156.271 25.9375 156.062 28.9583 158.562 31.5C161.604 34.5833 163.979 37.3333 165.688 39.75C166.021 40.25 166.479 40.3542 167.062 40.0625C170.729 38.0625 174.208 35.9375 177.5 33.6875C180.833 31.3958 184.125 28.9792 187.375 26.4375C187.708 26.1458 188.062 26.0625 188.438 26.1875C188.812 26.3125 189.021 26.6042 189.062 27.0625C189.438 29.3542 190.271 31.3125 191.562 32.9375C192.854 34.5625 194.188 36.1458 195.562 37.6875C200.604 43.6875 202.875 50.3542 202.375 57.6875C201.708 66.8125 198.625 73.7083 193.125 78.375C190.375 80.75 187.562 83.4167 184.688 86.375C181.812 89.2917 179.438 92.0625 177.562 94.6875C177.104 95.1875 176.646 95.2708 176.188 94.9375C173.854 93.4375 170.729 91.375 166.812 88.75C162.896 86.125 159.25 85.375 155.875 86.5ZM167.188 76.1875C169.438 77.0208 171.875 78.2292 174.5 79.8125C177.375 81.5208 179.938 83.0417 182.188 84.375C182.729 84.7083 183.188 84.5833 183.562 84C184.771 81.9583 186.167 79.2083 187.75 75.75C189.333 72.2917 190.188 68.3542 190.312 63.9375C190.354 59.3958 189.479 55.3125 187.688 51.6875C185.896 48.0625 183.917 44.6042 181.75 41.3125C179.333 37.8125 174.833 37.9583 168.25 41.75C167.667 42.0417 167.542 42.5 167.875 43.125C171.417 49.3333 172.708 55.1042 171.75 60.4375C170.875 65.5208 169.167 70.25 166.625 74.625C166.5 74.8333 166.479 75.125 166.562 75.5C166.646 75.875 166.854 76.1042 167.188 76.1875Z"
        />
        <path
          className="name-path"
          d="M216.312 78.3125L215.875 44.0625C215.875 43.2708 215.75 42.625 215.5 42.125C214.5 40.5 213.646 40.1042 212.938 40.9375L210.438 43.5C209.771 44.25 209.125 44.3333 208.5 43.75C207.917 43.1667 208.021 42.3542 208.812 41.3125C210.646 39.0208 212.292 36.7292 213.75 34.4375C215.208 32.1458 216.708 29.875 218.25 27.625C218.917 26.8333 219.688 26.8542 220.562 27.6875C222.312 29.8125 224.021 31.6875 225.688 33.3125C227.354 34.8958 229.729 36.5833 232.812 38.375C233.688 38.7083 234.062 39.1042 233.938 39.5625C233.854 40.0208 233.583 40.2708 233.125 40.3125C232.042 40.7292 231.083 41.4583 230.25 42.5C229.417 43.5 229 45.1875 229 47.5625V74C229 78.25 229.792 80.8542 231.375 81.8125C233.208 82.8958 234.708 82.4375 235.875 80.4375C236.292 79.6458 236.792 79.4792 237.375 79.9375C238 80.3542 238 81.0625 237.375 82.0625C236.125 84.0208 234.958 86 233.875 88C232.792 89.9583 231.688 91.9167 230.562 93.875C229.771 95.0417 228.792 95.2917 227.625 94.625C223.875 92.8333 221.042 90.6458 219.125 88.0625C217.25 85.4792 216.312 82.2292 216.312 78.3125ZM225.375 4.125C230 4.125 232.104 6.35417 231.688 10.8125C231.438 13.0625 230.021 15.3333 227.438 17.625C224.896 19.875 222.875 20.9792 221.375 20.9375C219.875 20.9375 218.729 19.75 217.938 17.375C217.146 15 216.833 12.8125 217 10.8125C217.125 8.89584 218.042 7.3125 219.75 6.0625C221.5 4.77084 223.375 4.125 225.375 4.125Z"
        />
        <path
          className="name-path"
          d="M265.938 70.4375C265.896 77.1458 267.146 81.0208 269.688 82.0625C269.979 82.2708 270.125 82.5625 270.125 82.9375C270.125 83.2708 269.979 83.5208 269.688 83.6875C265.938 86.5208 262.896 90.2917 260.562 95C260.312 95.3333 260 95.5 259.625 95.5C259.25 95.5 258.938 95.3333 258.688 95C256.354 90.2917 253.188 86.5417 249.188 83.75C248.938 83.5833 248.812 83.3125 248.812 82.9375C248.812 82.5625 248.958 82.2708 249.25 82.0625C251.958 81.0208 253.312 77.1458 253.312 70.4375V49.6875C253.312 45.4375 252.521 42.8333 250.938 41.875C249.104 40.6667 247.604 41.1042 246.438 43.1875C245.979 44.0625 245.458 44.2708 244.875 43.8125C244.292 43.3542 244.312 42.625 244.938 41.625C246.146 39.7083 247.292 37.75 248.375 35.75C249.5 33.75 250.646 31.75 251.812 29.75C252.479 28.5417 253.417 28.2917 254.625 29L254.688 29.0625C260.812 32.1458 264.479 35.9167 265.688 40.375C265.771 40.8333 265.979 41.1042 266.312 41.1875C266.646 41.2708 266.979 41.1875 267.312 40.9375C269.604 39.0208 271.958 37 274.375 34.875C276.792 32.75 278.438 30.7917 279.312 29C279.771 27.9167 280.521 27.9375 281.562 29.0625C284.062 31.6875 286.188 33.7708 287.938 35.3125C289.729 36.8542 292.75 38 297 38.75C297.75 38.9167 298.125 39.25 298.125 39.75C298.167 40.2083 297.917 40.5208 297.375 40.6875C296.292 41.1042 295.333 41.8333 294.5 42.875C293.667 43.875 293.25 45.5625 293.25 47.9375V74.375C293.25 78.625 294.042 81.2292 295.625 82.1875C297.458 83.3125 298.938 82.8542 300.062 80.8125C300.521 80.0208 301.042 79.8542 301.625 80.3125C302.25 80.7292 302.25 81.4375 301.625 82.4375C300.375 84.3958 299.188 86.375 298.062 88.375C296.979 90.3333 295.875 92.2917 294.75 94.25C294 95.4167 293.062 95.6875 291.938 95.0625C284.354 91.3958 280.562 85.9583 280.562 78.75L280.625 46.75C280.625 45.5 280.312 44.6875 279.688 44.3125H279.625V44.1875L274.312 39.8125C273.854 39.4792 273.375 39.4583 272.875 39.75L266.375 44.8125C266.083 45.0208 265.938 45.2708 265.938 45.5625V70.4375Z"
        />
      </mask>
      <path
        className="name-path"
        d="M11.375 86.4375C10.2917 87.1458 9.45833 87.125 8.875 86.375C8.29167 85.625 8.47917 84.8333 9.4375 84C16.6042 77.375 23.0208 73.1458 28.6875 71.3125C34.3542 69.4792 41.8125 71.5 51.0625 77.375C59.2708 82.625 66.5417 84.8958 72.875 84.1875C79.2083 83.4375 83.8333 79.6667 86.75 72.875C91.75 61.0833 90.2708 49.3958 82.3125 37.8125C74.3958 26.2292 63.8333 18.875 50.625 15.75C41.4583 13.5833 32.2917 14.375 23.125 18.125C13.9583 21.875 8.5625 27.4583 6.9375 34.875C4.9375 43.7917 6.83333 50.2708 12.625 54.3125C18.4583 58.3542 24.4583 59.6042 30.625 58.0625C31.4167 57.8542 32.2083 57.5625 33 57.1875C34.125 56.6875 34.9167 56.8542 35.375 57.6875C35.875 58.5208 35.5417 59.25 34.375 59.875C32.7917 60.5417 31.7708 60.9375 31.3125 61.0625C25.3958 62.9375 18.8542 61.9167 11.6875 58C4.52083 54.0833 0.9375 46.4375 0.9375 35.0625C0.9375 28.7708 2.91667 23.0625 6.875 17.9375C10.8333 12.7708 15.8958 8.64584 22.0625 5.5625C28.2292 2.4375 35 0.645837 42.375 0.187503C49.7917 -0.312497 57.0625 0.54167 64.1875 2.75C71.8958 5.66667 78.3542 9.875 83.5625 15.375C88.8125 20.875 92.625 26.9792 95 33.6875C97.375 40.3958 98.2083 47.3333 97.5 54.5C96.8333 61.6667 94.4583 68.3333 90.375 74.5C84.125 83.875 76.5208 90.1042 67.5625 93.1875C58.6042 96.3125 47.2083 94.1667 33.375 86.75C29.875 84.875 26.2917 83.5625 22.625 82.8125C19 82.0625 15.25 83.2708 11.375 86.4375ZM47.25 71.8125C46.0833 72.3958 45.2708 72.3125 44.8125 71.5625C44.3542 70.7708 44.5833 70.0417 45.5 69.375C50.2917 66.125 51.1458 62.75 48.0625 59.25C45.0208 55.75 42.2083 52.875 39.625 50.625C31.0833 43.3333 28.2917 37 31.25 31.625C34.2083 26.25 38.9583 21.75 45.5 18.125C46.6667 17.5417 47.4792 17.6667 47.9375 18.5C48.4375 19.2917 48.1667 20.0208 47.125 20.6875C40.9167 24.5625 42.1667 30.1458 50.875 37.4375C58.75 44.0208 62.2292 50.2292 61.3125 56.0625C60.3958 61.8958 55.7083 67.1458 47.25 71.8125Z"
        stroke="#000"
        strokeWidth="4"
        mask="url(#path-1-inside-1_11_7)"
      />
      <path
        className="name-path"
        d="M123 94.75C122.917 93.75 122.375 93 121.375 92.5C120.417 92 119.604 91.5 118.938 91C114.146 87.75 110.75 83.7917 108.75 79.125C106.792 74.4167 105.688 69.4375 105.438 64.1875C104.938 52.3125 108.146 43.2708 115.062 37.0625C117.521 34.8542 120.354 33.0625 123.562 31.6875C126.771 30.2708 130.062 29.125 133.438 28.25C134.479 28.0417 135.25 28.5625 135.75 29.8125L144.75 50.9375C145.167 52.0625 145.021 52.7708 144.312 53.0625H144.188L118.562 63C118.062 63.25 117.854 63.6458 117.938 64.1875C119.979 73.4375 123.396 79.375 128.188 82C132.979 84.5833 137.75 84.9583 142.5 83.125C143.333 82.75 143.854 82.8125 144.062 83.3125C144.312 83.8125 144.167 84.1875 143.625 84.4375C140.25 86.1458 137.021 87.8958 133.938 89.6875C130.896 91.4792 128.167 93.3542 125.75 95.3125C125.375 95.5625 124.812 95.6458 124.062 95.5625C123.354 95.4792 123 95.2083 123 94.75ZM117.188 59.4375C117.229 59.7708 117.375 60.0417 117.625 60.25C117.875 60.4167 118.188 60.4167 118.562 60.25L131.875 55.25C132.5 55 132.688 54.5625 132.438 53.9375L124.812 35.25C124.438 34.3333 123.688 34.2083 122.562 34.875C120.146 36.2917 118.5 39.5208 117.625 44.5625C116.75 49.6042 116.604 54.5625 117.188 59.4375Z"
        stroke="#000"
        strokeWidth="4"
        mask="url(#path-1-inside-1_11_7)"
      />
      <path
        className="name-path"
        d="M155.875 86.5C155.083 86.7917 154.646 86.6667 154.562 86.125C154.479 85.5417 154.792 84.9167 155.5 84.25C160.292 77.875 162.688 71.875 162.688 66.25C162.729 60.625 160.75 55.7708 156.75 51.6875C150.042 44.8125 147.167 39.4167 148.125 35.5C149.083 31.5417 152.312 26.8125 157.812 21.3125C158.354 20.7292 158.833 20.625 159.25 21C159.708 21.375 159.688 21.8542 159.188 22.4375C156.271 25.9375 156.062 28.9583 158.562 31.5C161.604 34.5833 163.979 37.3333 165.688 39.75C166.021 40.25 166.479 40.3542 167.062 40.0625C170.729 38.0625 174.208 35.9375 177.5 33.6875C180.833 31.3958 184.125 28.9792 187.375 26.4375C187.708 26.1458 188.062 26.0625 188.438 26.1875C188.812 26.3125 189.021 26.6042 189.062 27.0625C189.438 29.3542 190.271 31.3125 191.562 32.9375C192.854 34.5625 194.188 36.1458 195.562 37.6875C200.604 43.6875 202.875 50.3542 202.375 57.6875C201.708 66.8125 198.625 73.7083 193.125 78.375C190.375 80.75 187.562 83.4167 184.688 86.375C181.812 89.2917 179.438 92.0625 177.562 94.6875C177.104 95.1875 176.646 95.2708 176.188 94.9375C173.854 93.4375 170.729 91.375 166.812 88.75C162.896 86.125 159.25 85.375 155.875 86.5ZM167.188 76.1875C169.438 77.0208 171.875 78.2292 174.5 79.8125C177.375 81.5208 179.938 83.0417 182.188 84.375C182.729 84.7083 183.188 84.5833 183.562 84C184.771 81.9583 186.167 79.2083 187.75 75.75C189.333 72.2917 190.188 68.3542 190.312 63.9375C190.354 59.3958 189.479 55.3125 187.688 51.6875C185.896 48.0625 183.917 44.6042 181.75 41.3125C179.333 37.8125 174.833 37.9583 168.25 41.75C167.667 42.0417 167.542 42.5 167.875 43.125C171.417 49.3333 172.708 55.1042 171.75 60.4375C170.875 65.5208 169.167 70.25 166.625 74.625C166.5 74.8333 166.479 75.125 166.562 75.5C166.646 75.875 166.854 76.1042 167.188 76.1875Z"
        stroke="#000"
        strokeWidth="4"
        mask="url(#path-1-inside-1_11_7)"
      />
      <path
        className="name-path"
        d="M216.312 78.3125L215.875 44.0625C215.875 43.2708 215.75 42.625 215.5 42.125C214.5 40.5 213.646 40.1042 212.938 40.9375L210.438 43.5C209.771 44.25 209.125 44.3333 208.5 43.75C207.917 43.1667 208.021 42.3542 208.812 41.3125C210.646 39.0208 212.292 36.7292 213.75 34.4375C215.208 32.1458 216.708 29.875 218.25 27.625C218.917 26.8333 219.688 26.8542 220.562 27.6875C222.312 29.8125 224.021 31.6875 225.688 33.3125C227.354 34.8958 229.729 36.5833 232.812 38.375C233.688 38.7083 234.062 39.1042 233.938 39.5625C233.854 40.0208 233.583 40.2708 233.125 40.3125C232.042 40.7292 231.083 41.4583 230.25 42.5C229.417 43.5 229 45.1875 229 47.5625V74C229 78.25 229.792 80.8542 231.375 81.8125C233.208 82.8958 234.708 82.4375 235.875 80.4375C236.292 79.6458 236.792 79.4792 237.375 79.9375C238 80.3542 238 81.0625 237.375 82.0625C236.125 84.0208 234.958 86 233.875 88C232.792 89.9583 231.688 91.9167 230.562 93.875C229.771 95.0417 228.792 95.2917 227.625 94.625C223.875 92.8333 221.042 90.6458 219.125 88.0625C217.25 85.4792 216.312 82.2292 216.312 78.3125ZM225.375 4.125C230 4.125 232.104 6.35417 231.688 10.8125C231.438 13.0625 230.021 15.3333 227.438 17.625C224.896 19.875 222.875 20.9792 221.375 20.9375C219.875 20.9375 218.729 19.75 217.938 17.375C217.146 15 216.833 12.8125 217 10.8125C217.125 8.89584 218.042 7.3125 219.75 6.0625C221.5 4.77084 223.375 4.125 225.375 4.125Z"
        stroke="#000"
        strokeWidth="4"
        mask="url(#path-1-inside-1_11_7)"
      />
      <path
        className="name-path"
        d="M265.938 70.4375C265.896 77.1458 267.146 81.0208 269.688 82.0625C269.979 82.2708 270.125 82.5625 270.125 82.9375C270.125 83.2708 269.979 83.5208 269.688 83.6875C265.938 86.5208 262.896 90.2917 260.562 95C260.312 95.3333 260 95.5 259.625 95.5C259.25 95.5 258.938 95.3333 258.688 95C256.354 90.2917 253.188 86.5417 249.188 83.75C248.938 83.5833 248.812 83.3125 248.812 82.9375C248.812 82.5625 248.958 82.2708 249.25 82.0625C251.958 81.0208 253.312 77.1458 253.312 70.4375V49.6875C253.312 45.4375 252.521 42.8333 250.938 41.875C249.104 40.6667 247.604 41.1042 246.438 43.1875C245.979 44.0625 245.458 44.2708 244.875 43.8125C244.292 43.3542 244.312 42.625 244.938 41.625C246.146 39.7083 247.292 37.75 248.375 35.75C249.5 33.75 250.646 31.75 251.812 29.75C252.479 28.5417 253.417 28.2917 254.625 29L254.688 29.0625C260.812 32.1458 264.479 35.9167 265.688 40.375C265.771 40.8333 265.979 41.1042 266.312 41.1875C266.646 41.2708 266.979 41.1875 267.312 40.9375C269.604 39.0208 271.958 37 274.375 34.875C276.792 32.75 278.438 30.7917 279.312 29C279.771 27.9167 280.521 27.9375 281.562 29.0625C284.062 31.6875 286.188 33.7708 287.938 35.3125C289.729 36.8542 292.75 38 297 38.75C297.75 38.9167 298.125 39.25 298.125 39.75C298.167 40.2083 297.917 40.5208 297.375 40.6875C296.292 41.1042 295.333 41.8333 294.5 42.875C293.667 43.875 293.25 45.5625 293.25 47.9375V74.375C293.25 78.625 294.042 81.2292 295.625 82.1875C297.458 83.3125 298.938 82.8542 300.062 80.8125C300.521 80.0208 301.042 79.8542 301.625 80.3125C302.25 80.7292 302.25 81.4375 301.625 82.4375C300.375 84.3958 299.188 86.375 298.062 88.375C296.979 90.3333 295.875 92.2917 294.75 94.25C294 95.4167 293.062 95.6875 291.938 95.0625C284.354 91.3958 280.562 85.9583 280.562 78.75L280.625 46.75C280.625 45.5 280.312 44.6875 279.688 44.3125H279.625V44.1875L274.312 39.8125C273.854 39.4792 273.375 39.4583 272.875 39.75L266.375 44.8125C266.083 45.0208 265.938 45.2708 265.938 45.5625V70.4375Z"
        stroke="#000"
        strokeWidth="4"
        mask="url(#path-1-inside-1_11_7)"
      />
    </Icon>
  );
}

export default Devin;
